import React, { useState, useEffect } from "react";
import store from "store2";
import * as AzureService from "../api/ticketsApiAzure";
import * as tconst from "../common/Constants";
import ParseArea from "../common/ParseAreaList";
import EnrichTicket from "./EnrichTicket";
import Toolbar from "./Toolbar";
import OKRTable from "./OKRTable";
import LoginAdo from "../timeline/AzureDevOps/LoginAdo2";
import { PrepareData } from "../timeline/AzureDevOps/PrepareData";
import EditOKR from "./EditOKR";
import GetAssigneeListFromItems from "./GetAssigneeListFromItems";
import AddOKR from "./AddOKR";

//https://medium.com/weekly-webtips/implementing-a-rest-api-with-react-hooks-using-patterns-2ea1476e2a05
export const OkrPage = ({ auth, updateAuth }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState("20f1cb68-c351-4f02-9c71-a72c6facd459"); // "Test for oyvindh"
    const [items, setItems] = useState([]);
    const [itemIds, setItemsIds] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemQuery, setItemQuery] = useState({ query: "Select [System.Id] , [System.Title], [System.State], [Custom.EndIteration], [Custom.StartIteration], [System.Tags] From WorkItems Where  [State] <> 'Closed' AND [State] <> 'Removed' and [System.TeamProject] = @project order by [Custom.PlanningPriority] asc, [Custom.StartIteration] asc, [System.CreatedDate] asc" });
    const [refresh, setRefresh] = useState();
    const [showEditOKR, setShowEditOKR] = useState(false);
    const [showAddOKR, setShowAddOKR] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState(store.get("setSelectedArea") ? store.get("setSelectedArea") : "");
    const [selectedPeriod, setSelectedPeriod] = useState(store.get(tconst.STORE_SELECTED_PERIOD) ? store.get(tconst.STORE_SELECTED_PERIOD) : tconst.PERIOD_ALL);
    const [selectedStatus, setSelectedStatus] = useState(store.get(tconst.STORE_SELECTED_STATUS) ? store.get(tconst.STORE_SELECTED_STATUS) : tconst.STATUS_ALL);
    // eslint-disable-next-line no-unused-vars
    const [assigneeList, setAssigneeList ] = useState();
    const [selectedAssignee, setSelectedAssignee] = useState(store.get(tconst.STORE_SELECTED_ASSIGNEE) ? store.get(tconst.STORE_SELECTED_ASSIGNEE) : tconst.ASSIGNEE_ALL);
    const [selectedDueDateFilter, setSelectedDueDateFilter] = useState(store.get(tconst.STORE_SELECTED_DUE_DATE_FILTER) ? store.get(tconst.STORE_SELECTED_DUE_DATE_FILTER) : tconst.DUE_ALL);
    //#region useEffects
    //#region AreaPath
    useEffect(() => {
        setIsLoaded(false);
        if (auth && auth.un !== "" && auth.pw !== "") {

            AzureService.getAreaPath(auth).then(async (response) => {
                const _areas = ParseArea(response.value, "")
                        .filter((item) => {return !(item.toUpperCase().includes(tconst.AREA_ARCHIVE.toUpperCase()))})
                        .sort();
                setAreas(_areas);
                setIsLoaded(true);
                //            }).catch((error) => {
                //                updateAuth({un:"", pw: ""})
            });
        }
    }, [auth, updateAuth, refresh]);

    //#endregion AreaPath
    //#region Project
    useEffect(() => {
        setIsLoaded(false);
        if (auth && auth.un !== "" && auth.pw !== "") {

            AzureService.getProjects(auth).then(async (response) => {
                setProjects(response.value.map(project => { return { label: project.name, id: "" + project.id, name: project.name, value: project.id }; }));
                setIsLoaded(true);
            }).catch((error) => {
                updateAuth({ un: "", pw: "" });
            });
        }
    }, [auth, updateAuth]);
    //#endregion Project
    //#region  WorkItems
    useEffect(() => {
        setIsLoaded(false);

        const _selectedProject = projects ? projects.filter((filt) => {
            return filt.value === selectedProject;
        }) : null;

        const _selectedProjectName = _selectedProject && _selectedProject[0] ? _selectedProject[0].name : "";
        console.log("_selectedProjectName: " + _selectedProjectName);

        setItems([]);

        if (auth && auth.un !== "" && auth.pw !== "") {

            AzureService.getItemsList(auth, itemQuery, _selectedProjectName).then(
                async (response) => {
                    let ids = response.workItems.map((item) => item.id);
                    setItemsIds(ids);
                });
        }
    }, [auth, itemQuery, selectedProject, refresh, projects]);


    useEffect(() => {


        if (auth && auth.un !== "" && auth.pw !== "" && itemIds.length > 0) {
            setIsLoaded(false);


            let first = 0;
            let batchSize = 200;
            let pageIds = null;
            while (first < itemIds.length) {

                pageIds = itemIds.slice(first, first + batchSize);
                AzureService.getItems(auth, pageIds).then(
                    async (response) => {
                        const enrichedItems = response.value.map(item => {
                            EnrichTicket(item);
                            return item;
                        });

                        const _items = items;
                        _items.push(...enrichedItems);
                        setItems(_items);

                        const _assignees = GetAssigneeListFromItems(_items);

                        setAssigneeList(_assignees);
                        //const deadline = _items.map((item)=>{return item.assignee}).filter((value, index, self) => self.indexOf(value) === index);
                        
                        //Todo: Remove this log line later
                        console.log("2024-04-08: _assignees: "+ JSON.stringify(_assignees, null, 3))
/*
*/
                        console.log("items.length: " + items.length + " itemIds.length: " + itemIds.length);

                        if (items.length === itemIds.length) {
                            setIsLoaded(true);
                        }
                    }
                );
                first = first + batchSize;
            }
        }
        else {
            setIsLoaded(true);
            setItems([]);
        }
        // Will not trigger this on items - it is just used to write it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, itemIds]);

        

    //#endregion WorkItems
    //#endregion useEffects
    if (auth?.un === "" && auth.pw === "") {
        // Show Login
        return (
            <>
                <div>Missing Username and password</div>
                <LoginAdo updateAuth={updateAuth}></LoginAdo>
            </>
        );
    }
    else if (isLoaded) {

//        findPeriods(items, setPeriodList);

        const returnValue = PrepareData(items, selectedArea, selectedPeriod, selectedStatus, selectedAssignee, selectedDueDateFilter);
//       setAssigneeList(returnValue._assignees);

        const filteredItems = returnValue.filteredItems;

        return (
            <>
            <div>
            <AddOKR
                    showAddOKR={showAddOKR}
                    input = {selectedItem}

                    handleClose={(save)=>{
                        if (save) {
                            AzureService.createTicket(auth, "OKR", save.type, JSON.stringify(save.data));
                            setTimeout(() => {
                                setRefresh(Date.now())
                            }, 500);
                        }                    
                        setSelectedItem(null);
                        setShowAddOKR(false);
                    }}
                />

                <EditOKR 
                    showEditOKR={showEditOKR} 
                    selectedArea={selectedArea}
                    selectedItem={selectedItem}
                    statusList={tconst.STATUS_LIST.filter((item) => {return !(item.label.includes("<") && item.label.includes(">")) })}
                    assigneeList={tconst.ASSIGNEE_FILTER_LIST
                        .filter((item) => {return (item.label !== tconst.ASSIGNEE_ALL && item.label !== tconst.ASSIGNEE_UNASSIGNED)})
                    }
                    periodList={tconst.PERIODS_LIST.filter((item) => {return (item.value !== tconst.PERIOD_ALL && item.value !== tconst.PERIOD_MISSING)})}
                    handleClose={(save)=>{
                        if (save) {
                            AzureService.updateTicket(auth, save.itemId, JSON.stringify(save.data));
                            setTimeout(() => {
                                setRefresh(Date.now())
                            }, 500);
                        }
                        setSelectedItem(null);
                        setShowEditOKR(false)
                    }}
                />

                <Toolbar
                    areas={areas.map(area => { return { label: area.replace("\\OKR", "Sensio"), value: area }; })}
                    selectedArea={selectedArea}
                    onSelectedAreaChange={(event) => {
                        setSelectedAssignee("<All>")
                        store.set(tconst.STORE_SELECTED_ASSIGNEE, "<All>");
                        setSelectedArea(event.value);
                        store.set("setSelectedArea", event.value);
                    } }
                    selectedPeriod={selectedPeriod}
                    periodList={tconst.PERIODS_LIST}
                    onSelectedPeriodChange={(event) => {
                        setSelectedPeriod(event.value);
                        store.set(tconst.STORE_SELECTED_PERIOD, event.value);
                    } }
                    selectedStatus={selectedStatus}
                    statusList={tconst.STATUS_LIST}
                    onSelectedStatusChange={(event) => {
                        setSelectedStatus(event.value);
                        store.set(tconst.STORE_SELECTED_STATUS, event.value);
                    } }
                    selectedAssignee={selectedAssignee}
                    assigneeList={tconst.ASSIGNEE_FILTER_LIST.filter((item) => {return ((item.team === "All") || (item.team=== selectedArea))})}
//                    assigneeList={assigneeList}
                    onSelectedAssigneeChange={(event) => {
                        setSelectedAssignee(event.value);
                        store.set(tconst.STORE_SELECTED_ASSIGNEE, event.value);
                    } }
                    selectedDueDateFilter = {selectedDueDateFilter}
                    dueDateFilterList = {tconst.DUE_DATE_FILTER_LIST}
                    onSetSelectedDueDateFilter = { (event) => {
                        setSelectedDueDateFilter(event.value);
                        store.set(tconst.STORE_SELECTED_DUE_DATE_FILTER, event.value);
                    }}
                    onRefreshClick={()=>{setRefresh(Date.now())}}
                    showEditOKR={showEditOKR}
                />

            </div>
            <div  style={{backgroundColor: '#F0FFFF'}}/>


                <OKRTable
                    objectives={filteredItems} 
                    onItemClick={(item) => {
                        setSelectedItem(item);
                        setShowEditOKR(true);
                    }}
                    onCreateNewKR={(parent) => {
                        setSelectedItem({
                            parentId: parent.id,
                            type: "Key Result",
                            tags: selectedPeriod,
                            path: selectedArea
                        });

                        setShowAddOKR(true);
                    }}

                    onCreateNewObj={()=>{
                        // Create information and set into selected Item for transfering to the AddOKR dialog box

                        setSelectedItem({
                            parentId: null,
                            type: "Objective",
                            tags: selectedPeriod,
                            path: selectedArea
                        });
                        setShowAddOKR(true)
                
                }}
/>

            </>
        );
    }
    else {
        return (
            <div>Loading...</div>
        );
    }

};

export default OkrPage;


function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}