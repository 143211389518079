
export const STORE_SELECTED_PERIOD = "selectedPeriod";
export const STORE_SELECTED_STATUS = "selectedStatus";
export const STORE_SELECTED_ASSIGNEE = "selectedAssignee";
export const STORE_SELECTED_DUE_DATE_FILTER = "selected_due_date_filter";

export const AREA_ARCHIVE = "Archive";

export const PERIOD_ALL = "All";
export const PERIOD_MISSING = "Missing";

export const PERIODS_LIST = [
  { label: "< All >", value: PERIOD_ALL },
  { label: "< Missing >", value:  PERIOD_MISSING},
  { label: "24Q1", value: "24Q1" },
  { label: "24Q2", value: "24Q2" },
  { label: "24Q3", value: "24Q3" },
  { label: "24Q4", value: "24Q4" },
  { label: "25Q1", value: "25Q1" },
  { label: "25Q2", value: "25Q2" },
  { label: "25Q3", value: "25Q3" },
  { label: "25Q4", value: "25Q4" }];

  export const STATUS_ALL = "All";
  export const STATUS_MISSING = "Missing";
  export const STATUS_ACTIVE = "Active";
  export const STATUS_AT_RISK = "At Risk";
  export const STATUS_CANDIDATE = "Candidate";
  export const STATUS_COMMITTED = "Committed"; 
  export const STATUS_COMPLETED = "Completed"; 
  export const STATUS_PROPOSED = "Proposed";
  export const STATUS_PARTIAL = "Partial";
  export const STATUS_ON_TRACK = "On Track";
  export const STATUS_IDEA = "Idea";
  export const STATUS_OFF_TRACK = "Off Track";
  export const STATUS_MISSED = "Missed";
  export const STATUS_DROPPED = "Dropped"; 
  export const STATUS_DONE = "Done";

  export const STATUS_LIST=[
    { label: "< All >", value: STATUS_ALL },
//    { label: "<Missing>", value: STATUS_MISSING },
    { label: "< Active >", value: STATUS_ACTIVE },
    { label: "< Complete >", value: STATUS_COMPLETED },
    { label: "< Candidate >", value: STATUS_CANDIDATE },
    { label: STATUS_AT_RISK, value: STATUS_AT_RISK },
    { label: STATUS_COMMITTED, value: STATUS_COMMITTED },
    { label: STATUS_DONE, value : STATUS_DONE},
    { label: STATUS_DROPPED, value : STATUS_DROPPED},
    { label: STATUS_IDEA, value: STATUS_IDEA },
    { label: STATUS_MISSED, value : STATUS_MISSED},
    { label: STATUS_OFF_TRACK, value: STATUS_OFF_TRACK },
    { label: STATUS_ON_TRACK, value: STATUS_ON_TRACK },
    { label: STATUS_PARTIAL, value: STATUS_PARTIAL },
    { label: STATUS_PROPOSED, value: STATUS_PROPOSED },
  ];
  export const STATUS_ACTIVE_LIST =[STATUS_AT_RISK, STATUS_COMMITTED, STATUS_OFF_TRACK, STATUS_ON_TRACK]
  export const STATUS_COMPLETE_LIST =[STATUS_DONE, STATUS_DROPPED, STATUS_MISSED]
  export const STATUS_CANDIDATES_LIST =[STATUS_IDEA, STATUS_PROPOSED]

  export const DUE_ALL = 9999;
  export const DUE_MISSING = -1;
  export const DUE_OVERDUE = 0;
  export const DUE_NEXT_7_DAYS = 7;
  export const DUE_NEXT_30_DAYS = 30;
  export const DUE_NEXT_60_DAYS = 60;
  export const DUE_NEXT_90_DAYS = 90;

  export const DUE_DATE_FILTER_LIST=[
    { label: "<All>", value: DUE_ALL },
    { label: "<Missing>", value: DUE_MISSING },
    { label: "Overdue", value: DUE_OVERDUE },
    { label: "7 days", value: DUE_NEXT_7_DAYS },
    { label: "30 days", value: DUE_NEXT_30_DAYS },
    { label: "60 days", value: DUE_NEXT_60_DAYS },
    { label: "90 days", value: DUE_NEXT_90_DAYS },
  ] 
   
  export const ASSIGNEE_ALL = "<All>";
  export const ASSIGNEE_UNASSIGNED = "Unassigned";
  export const ASSIGNEE_TEAM_ALL = "All";
  
  export const ASSIGNEE_FILTER_LIST=[
    { label: ASSIGNEE_ALL, value: ASSIGNEE_ALL, team: ASSIGNEE_TEAM_ALL },
    { label: ASSIGNEE_UNASSIGNED, value: ASSIGNEE_UNASSIGNED, team: ASSIGNEE_TEAM_ALL },
    { label: "Øyvind Hopsnes", value: "Øyvind Hopsnes", team: "\\OKR\\Product Development" },
    { label: "Jon Slorer", value: "Jon Slorer", team: "\\OKR\\Product Development" },
    { label: "Gard H. Sveen", value: "Gard H. Sveen", team: "\\OKR\\Product Development\\Unity" },
    { label: "Mathias Lervold", value: "Mathias Lervold", team: "\\OKR\\Product Development\\Unity" },
    { label: "Freddy Mello", value: "Freddy Mello", team: "\\OKR\\Product Development\\Unity" },
    { label: "Sølve Egeland", value: "Sølve Egeland", team: "\\OKR\\Product Development\\Unity" },
    { label: "Jonas Jacobsen", value: "Jonas Jacobsen", team: "\\OKR\\Product Development\\Unity" },
    { label: "Sindre Bjørland Mørk", value: "Sindre Bjørland Mørk", team: "\\OKR\\Product Development\\Unity" },
    { label: "Eirik Holgersen", value: "Eirik Holgersen", team: "\\OKR\\Product Development\\Test" },
    { label: "Danay Pérez Ramírez", value: "Danay Pérez Ramírez", team: "\\OKR\\Product Development\\Test" },
    { label: "Rolf Michelsen", value: "Rolf Michelsen", team: "\\OKR\\Product Development\\Data platform" },
    { label: "Mats Hundvin Eliassen", value: "Mats Hundvin Eliassen", team: "\\OKR\\Product Development\\Data platform" },
    { label: "Mats Hundvin Eliassen", value: "Mats Hundvin Eliassen", team: "\\OKR\\Product Development\\Sensio Insight" },
    { label: "Ajith Rajasekharan", value: "Ajith Rajasekharan", team: "\\OKR\\Product Development\\Sensio Insight" },
    { label: "Andre Kleven", value: "Andre Kleven", team: "\\OKR\\Product Development\\InfraOps" },
    { label: "Anders Herbjørnsen", value: "Anders Herbjørnsen", team: "\\OKR\\Product Development\\InfraOps" },
    { label: "Eskild Kittelsen", value: "Eskild Kittelsen", team: "\\OKR\\Product Development\\InfraOps" },
    { label: "Lieding Wu", value: "Lieding Wu", team: "\\OKR\\Product Development\\InfraOps" },
    { label: "Svein-Erik Lund", value: "Svein-Erik Lund", team: "\\OKR\\Product Development\\InfraOps" },
    { label: "René Nordstrøm Jensen", value: "René Nordstrøm Jensen", team: "\\OKR\\Product Development\\RoomMate" },
    { label: "Øyvind Bjørnson-Langen", value: "Øyvind Bjørnson-Langen", team: "\\OKR\\Product Development\\RoomMate" },
    { label: "Tor Kveim", value: "Tor Kveim", team: "\\OKR\\Product Development\\RoomMate" },
    { label: "Øyvind Henriksen", value: "Øyvind Henriksen", team: "\\OKR\\Product Development\\RoomMate" },
    { label: "Johannes Schuck", value: "Johannes Schuck", team: "\\OKR\\Product Development\\RoomMate" },
    { label: "Falk Gjetrang", value: "Falk Gjetrang", team: "\\OKR\\Product Development\\RoomMate" },
    { label: "Preben Gundersen", value: "Preben Gundersen", team: "\\OKR\\Product Development\\APPS" },
    { label: "Mathias Lervold", value: "Mathias Lervold", team: "\\OKR\\Product Development\\APPS" },
    { label: "Anders Platou", value: "Anders Platou", team: "\\OKR\\Product Development\\APPS" },
    { label: "Espen Lybekk", value: "Espen Lybekk", team: "\\OKR\\Product Development\\APPS" },
    { label: "Rodion Gushchin", value: "Rodion Gushchin", team: "\\OKR\\Product Development\\APPS" },
    { label: "Bendik Hatlo", value: "Bendik Hatlo", team: "\\OKR\\Product Development\\APPS" },

  ]
//*************************************************************************
/* Azure DevOps Constants
 *************************************************************************/
export const AZURE_DEVOPS_BASE_URI = "https://dev.azure.com/sensio/";
export const AZURE_DEVOPS_API_URI = AZURE_DEVOPS_BASE_URI+"_apis/";
export const AZURE_DEVOPS_BASE_TICKET_URI = AZURE_DEVOPS_BASE_URI+"_workitems/edit/";

export const AZURE_Q_FIELDS = "Select [System.Id] , [System.Title], [System.State], [Custom.EndIteration], [Custom.StartIteration], [Custom.PlanningPriority], [System.AssignedTo] From WorkItems "
export const AZURE_Q1 = AZURE_Q_FIELDS+" WHERE [Custom.EndIteration] <> '' AND [State] <> 'Closed' AND [State] <> 'Removed' "
export const AZURE_Q_ORDER_BY = " order by [Custom.PlanningPriority] asc, [Custom.StartIteration] asc, [System.CreatedDate] asc";
export const AZURE_Q_PROJECT =  " and [System.TeamProject] = " 
export const AZURE_SPECS = ["FuncSpec", "DevPlan", "TestSpec"];

export const AF_AREA_PATH = "System.AreaPath";
export const AF_ASSIGNED_TO = "System.AssignedTo";
export const AF_DEPENDECIES = "Custom.Dependencies";
export const AF_DUE_DATE = "Microsoft.VSTS.Scheduling.DueDate";
export const AF_END_ITERATION = "Custom.EndIteration";
export const AF_ID = "id";
export const AF_INITIATIV = "Custom.Initiative";
export const AF_PARENT = "System.Parent";
export const AF_PLANNING_PRIORITY = "Custom.Planningpriority";
export const AF_PLANNING_STATUS = "Custom.PlanningStatus";
export const AF_PROGRESS = "Custom.Progress";
export const AF_PROJECT= "System.TeamProject"
export const AF_REL = "rel";
export const AF_REL_CHILD= "System.LinkTypes.Hierarchy-Forward";
export const AF_REL_PARENT= "System.LinkTypes.Hierarchy-Reverse";
export const AF_RELATION = "relations";
export const AF_REMAINING_WORK = "Microsoft.VSTS.Scheduling.RemainingWork";
export const AF_START_ITERATION = "Custom.StartIteration";
export const AF_STATE = "System.State"
export const AF_TAGS = "System.Tags";
export const AF_TITLE = "System.Title";
export const AF_WORKITEM_TYPE = "System.WorkItemType";


export const ADO_UNASSIGNED = "<Unassigned>"
export const ADO_INACTIVE_STATUSES=["Done","Closed"]

export const AZURE_PLANNING_QUERY_OPS = {
  label: "Operations Planning", 
  value:"0ec7dc3e-8f50-4f8a-919f-a83c0396c2b7",
  query: AZURE_Q1+ "AND [System.TeamProject] = 'Operations'" + AZURE_Q_ORDER_BY, 
}
export const AZURE_PLANNING_QUERY_TEST_FOR_OYVINDH = {
  label:"Test for oyvindh", 
  value: "{ea2c77fe-589a-4865-9f9f-6a149a649a3c}",
  query: AZURE_Q1+ "AND [System.TeamProject] = 'Test for oyvindh'" + AZURE_Q_ORDER_BY, 
}

export const AZURE_TIMELINE_FILTERS = {
  filterList:[
    AZURE_PLANNING_QUERY_OPS,
    AZURE_PLANNING_QUERY_TEST_FOR_OYVINDH
  ], 
  getFilterById  (filterId) {
    return this.filterList.filter((filt) => {
      return filt.id === filterId;
    })
  },
  getFilterByName  (filterName) {
    return this.filterList.filter((filt) => {
      return filt.name === filterName;
    })
  }

};


export function formatDate(date) {
  // prettier-ignore
  const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (date !== null) {
    return date.getDate() + ". " + months[date.getMonth()] + " " + date.getFullYear();
  } else return "";
}

export function addDaysToDate(date, numDays) {
  const _newDate = new Date(date);
  _newDate.setDate(_newDate.getDate() + numDays);

  return _newDate;
}export const INVALID_BACKGROUND_COLOR = "#FF71CD";
export const DONE_BACKGROUND_COLOR = "#9DBC98";

